// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_seebizsol/_assets/sass/style.react.scss";

@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import "~react-image-gallery/styles/css/image-gallery.css";

@import "~rsuite/dist/rsuite.min.css";

// Default Layout themes
// @import "./_seebizsol/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_seebizsol/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_seebizsol/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_seebizsol/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_seebizsol/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_seebizsol/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_seebizsol/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_seebizsol/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_seebizsol/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_seebizsol/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_seebizsol/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_seebizsol/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.text-filter,.select-filter, .filter-label{
  font-size: 0.8rem !important;
  height: auto !important;
}

.text-filter input,.select-filter input, .filter-label input,
.text-filter select,.select-filter select, .filter-label select{
  font-size: 0.8rem !important;
  padding: 3px !important;
  margin: 0;
  margin-top: 2px;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}

table{
      td,th{
      text-align: center !important;
      vertical-align: middle !important;
  }
}
table{
  td{
    .img{
      max-width: 100px;
      max-height: 100px;
    }
  }
}
.invisible{
  visibility: hidden;
}
.brand{
  transition:visibilty 1s;
}
.pull-right{
  float: right;
  left:0;
}

.circle{
  border-radius: 50%;
  min-width: 30px;
  min-height: 30px;
  max-width: 120px;
  max-height: 120px;
}

.video-container:after {
  content: "";
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}



.showcases {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  .content {
    z-index: 2;
  }
}
.about{
  position: absolute;
  top: 100%;
}
// .video-container {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
// }

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.cross_btn {
  display: inline-block;
  font-size: 14px;
  background-color: #f1f1f1;
  padding: 3px 8px;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.fa-times-circle {
  padding-left: 5px;
  padding-top: 5px;
  cursor: pointer;
}
