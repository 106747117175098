/* font awesome */
.sbi:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  font-size: small;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.sbi {
  font-size: 1.3rem;
}
/* google font icons */
.sbi-gf{
  font-size: 1.5rem;
}

.sbi-gf:before{
  font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.sbi-double-arrow-next:before { content: "\f100"; }
.sbi-double-arrow-back:before { content: "\f101"; }
.sbi-double-arrow-down:before { content: "\f102"; }
.sbi-double-arrow-up:before { content: "\f103"; }
.sbi-long-arrow-back:before { content: "\f104"; }
.sbi-arrow-next:before { content: "\f105"; }
.sbi-arrow-back:before { content: "\f106"; }
.sbi-long-arrow-next:before { content: "\f107"; }
.sbi-check:before { content: "\f108"; }
.sbi-arrow-down:before { content: "\f109"; }
.sbi-minus:before { content: "\f10a"; }
.sbi-long-arrow-down:before { content: "\f10b"; }
.sbi-long-arrow-up:before { content: "\f10c"; }
.sbi-plus:before { content: "\f10d"; }
.sbi-arrow-up:before { content: "\f10e"; }
.sbi-round:before { content: "\f10f"; }
.sbi-reload:before { content: "\f110"; }
.sbi-refresh:before { content: "\f111"; }
.sbi-solid-plus:before { content: "\f112"; }
.sbi-bold-close:before { content: "\f113"; }
.sbi-solid-minus:before { content: "\f114"; }
.sbi-hide:before { content: "\f115"; }
.sbi-code:before { content: "\f116"; }
.sbi-copy:before { content: "\f117"; }
.sbi-up-and-down:before { content: "\f118"; }
.sbi-left-and-right:before { content: "\f119"; }
.sbi-bold-triangle-bottom:before { content: "\f11a"; }
.sbi-bold-triangle-right:before { content: "\f11b"; }
.sbi-bold-triangle-top:before { content: "\f11c"; }
.sbi-bold-triangle-left:before { content: "\f11d"; }
.sbi-bold-double-arrow-up:before { content: "\f11e"; }
.sbi-bold-double-arrow-next:before { content: "\f11f"; }
.sbi-bold-double-arrow-back:before { content: "\f120"; }
.sbi-bold-double-arrow-down:before { content: "\f121"; }
.sbi-bold-arrow-down:before { content: "\f122"; }
.sbi-bold-arrow-next:before { content: "\f123"; }
.sbi-bold-arrow-back:before { content: "\f053"; }
.sbi-bold-arrow-up:before { content: "\f125"; }
.sbi-bold-check:before { content: "\f126"; }
.sbi-bold-wide-arrow-down:before { content: "\f127"; }
.sbi-bold-wide-arrow-up:before { content: "\f128"; }
.sbi-bold-wide-arrow-next:before { content: "\f129"; }
.sbi-bold-wide-arrow-back:before { content: "\f12a"; }
.sbi-bold-long-arrow-up:before { content: "\f12b"; }
.sbi-bold-long-arrow-down:before { content: "\f12c"; }
.sbi-bold-long-arrow-back:before { content: "\f12d"; }
.sbi-bold-long-arrow-next:before { content: "\f12e"; }
.sbi-bold-check-1:before { content: "\f12f"; }
.sbi-close:before { content: "\f00d"; }
.sbi-more-ver:before { content: "\f131"; }
.sbi-bold-more-ver:before { content: "\f132"; }
.sbi-more-hor:before { content: "\f133"; }
.sbi-bold-more-hor:before { content: "\f134"; }
.sbi-bold-menu:before { content: "\f135"; }
.sbi-drag:before { content: "\f136"; }
.sbi-bold-sort:before { content: "\f137"; }
.sbi-eye:before { content: "\f138"; }
.sbi-outline-info:before { content: "\f139"; }
.sbi-menu:before { content: "\f13a"; }
.sbi-menu-grid:before { content: "\f13b"; }
.sbi-wrench:before { content: "\f13c"; }
.sbi-gear:before { content: "\f13d"; }
.sbi-info:before { content: "\f13e"; }
.sbi-calendar-2:before { content: "\f13f"; }
.sbi-calendar:before { content: "\f140"; }
.sbi-calendar-today:before { content: "\f141"; }
.sbi-clock:before { content: "\f142"; }
.sbi-dots:before { content: "\f143"; }



.gf-dashboard:before{content: "\e871";}
.gf-insert-drive-file:before{content: "\e24d";}
.gf-pages:before{content: "\e7f9";}
.gf-dynamic-feed:before{content: "\ea14";}
.gf-anchor::before{content: "\f1cd";}
.gf-category::before{content: "\e574";}
.gf-construction::before{content: "\ea3c";}
.gf-menu::before{content: "\e5d2";}
.gf-person::before{content: "\e7fd";}